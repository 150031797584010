.logo {
    flex: 0 0 auto;
    margin:0 5px;
}

.logoContainer {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
}