.page.mall-order {
}

.page.mall-order > .content .item-order {
    border: 1px solid #efefef;
    border-radius: 5px;
    padding: 10px;
}

.page.mall-order > .content .item-order .ant-list-item-extra {
    margin-left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page.mall-order > .content .item-order .content .header {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;
    white-space: nowrap;
}


.page.mall-order > .content .item-order .content .header > .transaction-id {
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
}

.page.mall-order > .content .item-order .content .product-sku {
    padding: 10px;
}

.page.mall-order > .content .item-order .content .product-sku .sku-item {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    max-width: 100px;
}

.page.mall-order > .content .item-order .content .product-sku .sku-item img {
    object-fit: contain;
    height: 80px;
    width: 80px;
    padding: 10px;
    background-color: #f8f8f8;
    border-radius: 10px;
}


.page.mall-order > .content .item-order .content .buyer-message {
    padding: 2px 10px;
    margin: 5px 0;
    border-radius: 5px;
    background-color: #f8f8f8;
    height: 1.5em;
    line-height: 1.5em;
    white-space: nowrap;
    overflow: hidden;
    color: #444;
    text-overflow: ellipsis;
    cursor: pointer;
}

.productTitle {
    white-space: nowrap; /* 确保文本在一行内显示 */
    overflow: hidden; /* 隐藏超出容器的文本 */
    text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
    width: 80px; /* 定义容器宽度 */
}