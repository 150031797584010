.page.operator-user>.content .item-operator {
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 10px;
}

.page.operator-user>.content .item-operator .ant-list-item-extra {
    margin-left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}