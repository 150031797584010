.page.mall-product {}

.page.mall-product>.content .item-product {
    border: 1px solid #efefef;
    border-radius: 5px;
    padding: 10px;
}

.page.mall-product>.content .item-product .ant-list-item-extra {
    margin-left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page.mall-product>.content .item-product .content .header {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;
    white-space: nowrap;
}

.page.mall-product>.content .item-product .content .header .cate-product-name {
    flex: 1
}
.page.mall-product>.content .item-product .content .header .productName {
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
}

.cate-product-name {
    display: flex;
    align-items: center;
}

.page.mall-product>.content .item-product .content .product-images {
    padding: 10px;
}

.page.mall-product>.content .item-product .content .product-images .item-image img {
    object-fit: contain;
    height: 80px;
    width: 80px;
    padding: 10px;
    background-color: #f8f8f8;
    border-radius: 10px;
}

.product-operations {
    display: flex;
    align-items: center;
}

.product-detail-rich-title:before {
    display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
}