
#content-form .content-anchor-box {
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(-280px);
    width: 260px !important;
    background-color: white;
    border-radius: 4px;
    padding: 20px;
    max-height: 60vh;
    overflow-y: scroll;
}