
.compare-table {
    margin: 20px 0;
    overflow-x: scroll;
    padding-bottom: 10px;
    width: 900px;
}

.compare-table table {
    min-width: 900px;
    min-height: 220px;
    border-collapse: collapse;
    border: 5px solid #e6e6e6;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 220px;
    color: #606266;
    position: relative;
}

.compare-table table img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 220px;
    height: 220px;
    opacity: 0.4;
    pointer-events: none;
}

.compare-table th {
    padding: 8px;
}

.compare-table td {
    padding: 8px;
    min-width: 100px;
    border: 2px solid #e6e6e6;
}

.compare-table td p {
    margin: 14px 0;
}

.selected-grid {
    background-color: rgba(88, 190, 191, 0.5) !important;
}