
.order-detail-form .sku-item {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

.order-detail-form .sku-item img {
    object-fit: contain;
    height: 80px;
    width: 80px;
    padding: 10px;
    background-color: #f8f8f8;
    border-radius: 10px;
}

.order-detail-form .ant-tabs-content-holder {
    height: 600px;
    overflow-y: scroll;
}


.order-detail-form .ant-tabs-content-holder::-webkit-scrollbar {
    display: none;
}