.page.notification-scene>.content .item-scene {
    background: rgb(250, 250, 250);
    padding: 10px;
    border-radius: 10px;
}

.page.notification-scene>.content .item-scene .ant-list-item-extra {
    margin-left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ant-avatar-group {
    display: inline-flex;
}

.user-avatar-wrap {
    align-items: center;
    cursor: pointer;
    display: inline-flex !important;
    flex-direction: column;
}

.user-avatar-wrap .nickname {
    color: #1890ff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}