.remark {
  display: flex;
  flex-flow: row nowrap;
  height: auto !important;
}
.label {
  flex: 0 0 auto;
}
.input {
  flex: 1 1 auto;
}
