
.product-category-tree-branch {
    display: flex;
    align-content: center;

    padding: 8px 0;
    font-size: 16px;
}

.ant-tree-switcher {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
}