
.order-express-box {
    overflow-y: scroll;
    padding: 10px;
}

.express-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
}

.express-detail {
    margin-top: 10px;
}

.express-detail .ant-steps-item-title {
    font-size: 16px !important;
    color: #1677ff !important;
}