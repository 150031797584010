
.mind-map-box {
    height: 70vh;
    width: 100%;
    position: relative;
}

.mind-map-box #mind-map {
    width: 100%;
    height: calc(70vh - 50px);
}

.mind-map-box .quill-input {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mind-map-box .ql-editor-container {
    background-color: white;
    box-shadow: 0 0 8px 2px #d4d4d4;
    height: 200px;
    display: flex;
    flex-direction: column;
    max-width: 50%;
}

#mind-map me-tpc p {
    margin: 0;
}