.user-avatar-wrap {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
}

.user-avatar-wrap .user-avatar .ant-avatar.online {
    box-shadow: 0 0 0 2px #0f0;
    border: 1px solid #fff;
}

.user-avatar-wrap .user-avatar {
    margin-right: 10px;
}

.user-avatar-wrap .nickname {
    color: #1890ff;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
}