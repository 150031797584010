.medias-form {
    padding-right: 20px;
}

.medias-form .ant-collapse-content-box {
    padding-right: 0 !important;
    padding-bottom: 0 !important;
}

.medias-form .ant-collapse {
    border-radius: 8px 0 0 0 !important;
    margin-bottom: 12px !important;
    background-color: transparent !important;
}

.medias-form .ant-collapse .ant-collapse {
    margin: 0 !important;
    margin-top: 12px !important;
}

.medias-form .ant-collapse-item {
    border-radius: 8px 0 0 0 !important;
    background-color: transparent !important;
}

.medias-form .ant-collapse-content {
    background-color: transparent !important;
}

.medias-form .ant-form-item {
    width: 100% !important;
    margin-bottom: 12px !important;
}

.medias-form .ant-divider-horizontal {
    margin: 12px 0 !important;
}

.medias-form .required-media::before {
    display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
}

.medias-form .group-media-name {
    font-weight: bold;
}

.medias-form .fixed-length-label {
    width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.anchor-highlight {
    animation: anchor-blink 2s ease-in-out;
    border-radius: 8px 0 0 0;
}

@keyframes anchor-blink {
    0% {
        background-color: rgba(69, 193, 196, 0.5);
    }
    100% {
        background-color: transparent;
    }
}

.medias-form .ql-image-dialog button {
    background-color: #6918b4 !important;
    width: 132px !important;
}

.medias-form .ql-editor-container .ql-container {
    overflow: visible;
}