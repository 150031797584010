.order-shipping-form {}

.order-shipping-form .order-details {
    max-height: 250px;
    overflow-y: auto;
}

.order-shipping-form .order-details .sku-item {
    color: #444;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f8f8f8;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
}